import React from 'react'
import Link from 'gatsby-link'

const Settings = () => (
  <div>
    <h1>Einstellungen</h1>
    <Link to="/">zurück</Link>
  </div>
)

export default Settings
